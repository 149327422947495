import Image from "next/image"
import MarkdownText from "components/atoms/markdown"

export default function InfoSection({
    heading,
    headingAs = 'h2',
    innerHeadingsAs = 'h3',
    blocks,
    className = '',
}) {
    const Heading = headingAs;
    const InnerHeading = innerHeadingsAs;
    return (
        <section className={`flex flex-col gap-4 max-w-7xl mx-auto px-4 py-8 ${className}`}>
            <Heading className="text-2xl font-normal text-center text-pink-500">{heading}</Heading>
            <div className="grid md:grid-cols-3 gap-8">
                {(blocks || []).map((block, idx) => (
                    <div key={idx} className="flex-1 flex flex-col gap-4 p-6 items-center text-center">
                        <div className="w-[125px] h-[125px]">
                            {block.icon?.data?.attributes?.url && (
                                <Image 
                                    src={block.icon.data.attributes.url}
                                    width={block.icon.data.attributes.width}
                                    height={block.icon.data.attributes.height}
                                    alt={block.icon.data.attributes.alternativeText}
                                    layout="responsive"
                                    unoptimized // ✅ disables Vercel optimization
                                />
                            )}
                        </div>
                        <div>
                            <InnerHeading className="text-xl font-bold text-pink-500">{block.heading}</InnerHeading>
                        </div>
                        <div className="text-left">
                            <MarkdownText className="text-gray-500">{block.body}</MarkdownText>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
